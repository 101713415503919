'use client';

import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  TextField,
} from "@mui/material";
import numeral from "numeral";
import { useApolloClient, useMutation } from "@apollo/client";

import { AppContext } from "../../core/AppContext";
import { GET_SKU_BY_BARCODE } from "../../sku/api/queries"
import { ADD_WISHLIST_ITEM, UPDATE_WISHLIST_ITEM } from "../../registry/api/mutations"

const EditItem = (props) => {
    let { wishlist,  editItemDialog, setEditItemDialog, fetchAndResetRegData, item } = props;

    const client = useApolloClient();
    const [isBundle, setIsBundle] = useState(false)
    const [quantity, setQuantity] = useState(0)
    const [bundleQty, setBundleQty] = useState(1)
    const [skuBarcode, setSkuBarcode] = useState("")
    const [productDetails, setProductDetails] = useState(null)
    const [isLoading, setIsLoading] = useState(false);
    const { showSnackBar, showProgressIndicator } = useContext(AppContext);
    const [doUpdateWishlistItem] = useMutation(UPDATE_WISHLIST_ITEM);

    const checkBundle = (event) => {
        const { checked } = event.target;
        setIsBundle(checked)

        if(checked) {
            setBundleQty(1)
        }
    };

    const addWishlistItem = async () => {
        showProgressIndicator(true)
        const { loading, data } = await doUpdateWishlistItem({
            variables: {
                _id: item?._id,
                quantity: Number(quantity),
                isBundle: isBundle,
                bundleQty: Number(bundleQty),
            },
        });

        console.log('data', data)
        const res = JSON.parse(data.updateWishlistItem)
        if(res.success) {
            await showSnackBar({
                open: true,
                message: res.message,
                severity: "success"
            });
            showProgressIndicator(false)
            setEditItemDialog(false)
            resetParams()
            fetchAndResetRegData()
        }
        else {
            await showSnackBar({
                open: true,
                message: res.message,
                severity: "error",
            });
            showProgressIndicator(false)
        }
    };

    const cancelBtn = async () => {
        setEditItemDialog(false)
        resetParams()
    }

    const resetParams = () => {
        setIsBundle(false)
        setBundleQty(0)
        setQuantity(0)
    }

    useEffect(() => {
        console.log('trigger useEffect')
        setIsBundle(item?.isBundle)
        setBundleQty(item?.bundleQty)
        setQuantity(item?.quantity)
    }, [item, editItemDialog])

    return (
        <Dialog open={editItemDialog} onClose={() => setEditItemDialog(false)} fullWidth maxWidth="sm">
        <DialogTitle>Edit Item</DialogTitle>
        <DialogContent dividers>
            <Grid container spacing={1}>
            {
                item && (
                <Grid  item sm={12} >
                    <Grid item sm={12}>
                    {/* <Grid container style={{ border: "solid 1px #cccccc", padding: 5 }}> */}
                        <Grid item sm={12}>
                        <div><strong>Product Name:</strong> {item.productName}</div>
                        <div><strong>SKU:</strong> {item.skuCode}</div>
                        <div><strong>Price:</strong> {numeral(item.currentPrice).format("0,000.00")}</div>
                        </Grid>
                    {/* </Grid> */}
                    </Grid>
                    <Grid container spacing={1} style={{ marginTop: '1rem'}}>
                    <Grid sm={12}>
                        <Checkbox 
                        onChange={checkBundle} checked={isBundle}
                        /> Is Bundle?
                    </Grid>
                    <Grid item sm={3}>
                        <TextField 
                            type="number"
                            label="Wants"
                            name="quantity"
                            value={quantity}
                            defaultValue={quantity}
                            onChange={(e) => {
                                setQuantity(e.target.value)
                            }}
                            InputProps={{
                                inputProps: { 
                                   min: 1 
                                }
                            }}
                        />
                    </Grid>
                    {
                        isBundle && (
                        <Grid item sm={3}>
                            <TextField 
                                type="number"
                                label="Quantity"
                                name="bundleQty"
                                value={bundleQty}
                                defaultValue={quantity}
                                onChange={(e) => {
                                    setBundleQty(e.target.value)
                                }}
                                InputProps={{
                                    inputProps: { 
                                       min: 2 
                                    }
                                }}
                            />
                        </Grid>
                        )
                    }
                    </Grid>
                </Grid>
                
                )
            }
            </Grid>
            <DialogActions>
            <Button 
                onClick={() => cancelBtn() } 
                variant="contained" 
                color="error"
            >
                CANCEL
            </Button>
            <Button
                onClick={addWishlistItem}
                variant="contained"
                color="primary"
                disabled={item ? false : true }
            >
                SUBMIT
            </Button>
            </DialogActions>
        </DialogContent>
        </Dialog>
    );
};

export default EditItem;
