import { gql } from "@apollo/client";
import { wishlistFileds } from "./queries";

export const DOWNLOAD_REGISTRY_REPORT = gql`
  mutation downloadRegistryReport($searchParam: SearchRegistryParam) {
    downloadRegistryReport(searchParam: $searchParam)
  }
`;

export const UPDATE_REGISTRY_STATUS = gql`
  mutation updateRegistryStatus( $wishlistId:String, $status:String ){
    updateRegistryStatus( wishlistId:$wishlistId, status:$status ){
      ${wishlistFileds}
    }
  }
`;

export const CREATE_ORDER = gql`
  mutation createOrder($order: OrderInput) {
    createOrder(order: $order)
  }
`;

export const CANCEL_OFFLINE_ORDER = gql`
  mutation cancellOfflineOrder($id: String) {
    cancellOfflineOrder(id: $id)
  }
`;

export const UPDATE_REGISTRY = gql`
  mutation updateWishlist( $wishlist:UpdateWishlistInput ){
    updateWishlist( wishlist:$wishlist )
  }
`;

export const DELETE_WISHLIST_ITEM = gql`
  mutation deleteWishlistItem($wishlistId: String, $wishListItemId: String, $owner: String) {
    deleteWishlistItem(wishlistId: $wishlistId, wishListItemId: $wishListItemId, owner: $owner)
  }
`;

export const UPDATE_SHOPIFY_CHECKOUT_STATUS = gql`
  mutation updateShopifyCheckoutStatus( $shopifyCheckoutId:String, $status: String ){
    updateShopifyCheckoutStatus( shopifyCheckoutId:$shopifyCheckoutId, status: $status )
  }
`;

export const UPDATE_OFFLINE_ORDER_STATUS = gql`
  mutation updateOfflineOrderStatus( $id:String, $status: String ){
    updateOfflineOrderStatus( id:$id, status: $status )
  }
`;

export const UPDATE_OFFLINE_ORDER = gql`
  mutation updateOfflineOrder($params: OfflineOrderInput){
    updateOfflineOrder(params: $params)
  }
`;

export const ADD_WISHLIST_ITEM = gql`
  mutation addWishlistItem($skuId: String, $babySkuCode: String, $wishlistId: String, $quantity: Int, $owner: String, $isBundle: Boolean, $bundleQty: Int){
    addWishlistItem(skuId: $skuId, babySkuCode: $babySkuCode, wishlistId: $wishlistId, quantity: $quantity, owner: $owner, isBundle: $isBundle, bundleQty: $bundleQty)
  }
`;

export const UPDATE_WISHLIST_ITEM = gql`
  mutation updateWishlistItem($_id: String, $quantity: Int, $isBundle: Boolean, $bundleQty: Int){
    updateWishlistItem(_id: $_id, quantity: $quantity, isBundle: $isBundle, bundleQty: $bundleQty)
  }
`;

export const UPDATE_SHOPIFY_CHECKOUT = gql`
  mutation updateShopifyCheckout($params: ShopifyCheckoutInput) {
    updateShopifyCheckout(params: $params)
  }
`

