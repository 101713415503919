import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import RegistryForm from "./RegistryForm";
import Misc from "./Misc";
import { Button, Card, Grid } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  card: {
    padding: 15,
  },
}));

const Main = (props) => {
  const {
    registry,
    updateRegistry,
    updateCustomer,
    doSave,
    disabled,
    setRegistry,
    showError,
    showSuccess,
    toggleDiscount,
    saveAddress,
    saveBranch,
  } = props;
  let have = 0;
  let reserved = 0;
  const classes = useStyles();

  let wishlistItems = registry?.wishlistItems
  wishlistItems.map((v) => {
    v?.reservations?.map((v2) => {
      if(v2?.quantity && v2.checkoutDate) {
        have = have + v2?.quantity
      }
      if(v2?.quantity && v2.reserveDate) {
        reserved = reserved + v2?.quantity
      }
    })
  })

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={8}>
        <Card className={classes.card} elevation={1}>
          <RegistryForm
            registry={registry}
            updateRegistry={updateRegistry}
            saveAddress={saveAddress}
            updateCustomer={updateCustomer}
            disabled={disabled}
            toggleDiscount={toggleDiscount}
            totalHave={have}
            totalReserved={reserved}
          />
          <br />
          <br />
          {!disabled && (
            <Button autoFocus variant="contained" color="secondary" onClick={() => doSave()}>
              Save
            </Button>
          )}
        </Card>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Misc
          registry={registry}
          setRegistry={setRegistry}
          showSuccess={showSuccess}
          showError={showError}
          saveAddress={saveAddress}
          disabled={disabled}
          saveBranch={saveBranch}
          totalHave={have}
          totalReserved={reserved}
        />
      </Grid>
    </Grid>
  );
};

export default Main;
