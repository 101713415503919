import { gql } from "@apollo/client";

const GET_SKU_BY_BARCODE = gql`
  query getSkuByBarcode($skuBarcode: String, $skuCode: String, $description: String) {
    getSkuByBarcode(skuBarcode: $skuBarcode, skuCode: $skuCode, description: $description) {
      _id
      productName
      skuCode
      primaryImage
      currentPrice
      babySkuCode
      environmentCode
    }
  }
`;

const GET_SKU_AVAILABILITY = gql`
  query getSkuAvailability($sku: SkuInput) {
    getSkuAvailability(sku: $sku)
  }
`;

export { GET_SKU_BY_BARCODE, GET_SKU_AVAILABILITY };
