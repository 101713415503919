import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: "'HenrySansRegular'",
    },
    htmlFontSize: 10,
  },
  palette: {
    primary: {
      main: "#333",
      contrastText: "#fff",
    },
    secondary: {
      main: "#333",
      contrastText: "#fff",
      // main: "rgb(0, 119, 205)",
    },
    error: {
      main: "rgb(255, 0, 0)",
      contrastText: "#fff",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          fontSize: "62.5%",
        },
      },
    },
  },
});

export default theme;
