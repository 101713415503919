import { gql } from "@apollo/client";

export const UPLOAD_SKU_IMAGE = gql`
  mutation uploadImage($base64: String!, $skuId: String!) {
    uploadImage(skuId: $skuId, base64: $base64)
  }
`;

export const UPLOAD_WISHLIST_IMAGE = gql`
  mutation uploadWishlistItemImage($wishlistItemId: String!, $base64: String!) {
    uploadWishlistItemImage(wishlistItemId: $wishlistItemId, base64: $base64)
  }
`;

export const SAVE_SKU = gql`
  mutation saveSku(
    $app: String
    $skuBarcode: String
    $environmentCode: String
    $babySkuCode: String
    $currentPrice: Float
    $name: String
  ) {
    saveSku(
      app: $app
      skuBarcode: $skuBarcode
      environmentCode: $environmentCode
      babySkuCode: $babySkuCode
      currentPrice: $currentPrice
      name: $name
    )
  }
`;
