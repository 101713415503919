import { Suspense, useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { Link, useNavigate } from "react-router-dom";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { Menu, MenuItem, Typography, styled } from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import CategoryIcon from "@mui/icons-material/Category";
import ContactPageIcon from '@mui/icons-material/ContactPage';
import ViewListIcon from '@mui/icons-material/ViewList';
import StoreIcon from "@mui/icons-material/Store";
import ImageIcon from "@mui/icons-material/Image";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import Search from "@mui/icons-material/Search";
import Sell from "@mui/icons-material/Sell";
import RateReviewSharp from "@mui/icons-material/RateReviewSharp";
import InstallMobile from "@mui/icons-material/InstallMobile";
import CardGiftcard from "@mui/icons-material/CardGiftcard";
import Assessment from "@mui/icons-material/Assessment";
import Report from "@mui/icons-material/Report"
import StyleIcon from "@mui/icons-material/Style";
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

let menus = [
  {
    label: "Dashboard",
    icon: <DashboardIcon />,
    url: "/dashboard",
  },
  {
    label: "Analytics",
    icon: <Assessment />,
    url: "/analytics",
  },
  {
    label: "Search Registries",
    icon: <Search />,
    url: "/registries",
  },
  {
    label: "Report",
    icon: <Report />,
    url: "/report",
  },
  {
    label: "Customer Feedback",
    icon: <RateReviewSharp />,
    url: "/feedback",
  },
  { label: "SKU", icon: <Sell />, url: "/skus" },
  { label: "In store SKU", icon: <QrCodeScannerIcon />, url: "/instoresku" },
  {
    label: "Find Registry Request",
    icon: <Report />,
    url: "/find-registry",
  },
];

const cmsMenus = [
  { label: "Branches", icon: <StoreIcon />, url: "/branches" },
  { label: "Banners", icon: <ImageIcon />, url: "/banners" },
  {
    label: "Gift Wrap",
    icon: <CardGiftcard />,
    url: "/giftwraps",
  },
  {
    label: "Widgets",
    icon: <ViewCarouselIcon />,
    url: "/widgets",
  },
  // {
  //   label: "Brands",
  //   icon: <ViewListIcon />,
  //   url: "/brand",
  // },
  {
    label: "Categories",
    icon: <CategoryIcon />,
    url: "/category",
  },
  {
    label: "GRAs",
    icon: <ContactPageIcon />,
    url: "/gras",
  },
];

const adminMenus = [
  {
    label: "User Management",
    icon: <PeopleIcon />,
    url: "/users",
  },
  // {
  //   label: "App Version",
  //   icon: <InstallMobile />,
  //   url: "/app-version",
  // },
];

const PrivateLayout = (props) => {
  const { Component } = props;
  const theme = useTheme();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const role = sessionStorage.getItem("role");
  const [selectedList, setselectedList] = useState(1);

  const handleListItemClick = (event, selected) => {
    setselectedList(selected);
  };
  
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const doLogout = () => {
    handleClose();
    sessionStorage.clear();
    window.location.reload();
  };

  const showProfile = () => {
    handleClose();
    navigate("/profile");
  };

  if(role === "User"){
    menus = menus.filter((v) => v.label !== "Analytics")
  } 

  useEffect(() => {
    if (!sessionStorage.getItem("token")) {
      navigate(`/login`);
    }
  }, [Component]);

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <AppBar position="fixed" open={open}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Box sx={{ flexGrow: 1 }}>
              <Box
                component="img"
                sx={{
                  display: { xs: "none", md: "flex" },
                  mr: 0,
                  height: 30,
                  cursor: "pointer",
                }}
                alt="Gift Registry Console"
                src="/crate-barrel-logo-white.png"
                onClick={() => {
                  navigate("/dashboard");
                }}
              />
            </Box>
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
                // disableFocusRipple={true}
                disableRipple={true}
              >
                <Box sx={{ paddingRight: "1rem" }}>
                  <Typography>{sessionStorage.getItem("email")}</Typography>
                </Box>
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={showProfile}>Profile</MenuItem>
                <MenuItem onClick={doLogout}>Logout</MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? <ChevronRightIcon color="primary" /> : <ChevronLeftIcon color="primary" />}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            {menus.map(({ label, icon, url }) => (
              <ListItem button key={label} disablePadding sx={{ display: "block" }} component={Link} to={url}>
                <ListItemButton
                  selected={selectedList === label}
                  onClick={(event) => handleListItemClick(event, label)}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {icon}
                  </ListItemIcon>
                  <ListItemText primary={label} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Divider />
          <List>
            {role === "Admin" &&
              cmsMenus.map(({ label, icon, url }) => (
                <ListItem button key={label} disablePadding sx={{ display: "block" }} component={Link} to={url}>
                  <ListItemButton
                    selected={selectedList === label}
                    onClick={(event) => handleListItemClick(event, label)}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {icon}
                    </ListItemIcon>
                    <ListItemText primary={label} sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </ListItem>
              ))}
          </List>

          <Divider />
          <List>
            {role === "Admin" &&
              adminMenus.map(({ label, icon, url }) => (
                <ListItem button key={label} disablePadding sx={{ display: "block" }} component={Link} to={url}>
                  <ListItemButton
                    selected={selectedList === label}
                    onClick={(event) => handleListItemClick(event, label)}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {icon}
                    </ListItemIcon>
                    <ListItemText primary={label} sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </ListItem>
              ))}
          </List>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Suspense
            fallback={
              <Box
                sx={{
                  position: "fixed",
                  display: "flex",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 1,
                }}
              >
                <CircularProgress />
              </Box>
            }
          >
            <Box sx={{ paddingTop: "5rem" }}>
              <Component />
            </Box>
          </Suspense>
        </Box>
      </Box>
    </>
  );
};

export default PrivateLayout;
