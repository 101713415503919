import { lazy } from "react";
import RegistryInfo from "../../registry/RegistryInfo";
import AppVersion from "../../appversion";
const Dashboard = lazy(() => import("../../dashboard"));
const Analytics = lazy(() => import("../../analytics"));
const InstoreSku = lazy(() => import("../../instore-sku"));
const Report = lazy(() => import("../../report"));
const Profile = lazy(() => import("../../profile"));
const Users = lazy(() => import("../../user"));
const UserInfo = lazy(() => import("../../user/UserInfo"));
const Branch = lazy(() => import("../../branch"));
const BranchInfo = lazy(() => import("../../branch/BranchInfo"));
const Widget = lazy(() => import("../../widget"));
const WidgetInfo = lazy(() => import("../../widget/WidgetInfo"));
const Registry = lazy(() => import("../../registry/index"));
const Sku = lazy(() => import("../../sku/index"));
const Feedback = lazy(() => import("../../feedback/index"));
const GiftWraps = lazy(() => import("../../giftwrap/index"));
const GiftWrap = lazy(() => import("../../giftwrap/edit"));
const Brand = lazy(() => import("../../brand/index"));
const Category = lazy(() => import("../../category/index"));
const Gra = lazy(() => import("../../gra/index"));
const FindRegistry = lazy(() => import("../../findRegistry"));
const CMS = lazy(() => import("../../cms"));


let privateRoutes = [
  {
    Component: Dashboard,
    path: "/dashboard",
  },
  {
    Component: Analytics,
    path: "/analytics",
  },
  {
    Component: Profile,
    path: "/profile",
  },
  {
    Component: Users,
    path: "/users",
  },
  {
    Component: Gra,
    path: "/gras",
  },
  {
    Component: AppVersion,
    path: "/app-version",
  },
  {
    Component: UserInfo,
    path: "/user-info/:userId",
  },
  {
    Component: UserInfo,
    path: "/create-user",
  },
  {
    Component: BranchInfo,
    path: "/create-branch",
  },
  {
    Component: BranchInfo,
    path: "/branch-info/:branchId",
  },
  {
    Component: Branch,
    path: "/branches",
  },
  {
    Component: CMS,
    path: "/banners",
  },
  {
    Component: Widget,
    path: "/widgets",
  },
  {
    Component: WidgetInfo,
    path: "/widget-info/:widgetId",
  },
  {
    Component: WidgetInfo,
    path: "/create-widget",
  },
  {
    Component: Registry,
    path: "/registries",
  },
  {
    Component: RegistryInfo,
    path: "/registry/:registryId",
  },
  {
    Component: Report,
    path: "/report"
  },
  {
    Component: Report,
    path: "/reports/myReports"
  },
  {
    Component: Sku,
    path: "/skus",
  },
  {
    Component: InstoreSku,
    path: "/instoresku",
  },
  {
    Component: Feedback,
    path: "/feedback",
  },
  {
    Component: GiftWraps,
    path: "/giftwraps",
  },
  {
    Component: GiftWrap,
    path: "/giftwrap/:id",
  },
  {
    Component: Category,
    path: "/category",
  },
  {
    Component: FindRegistry,
    path: "/find-registry",
  },
];

if(sessionStorage.getItem("role") === "User"){
  privateRoutes = privateRoutes.filter((route) => {
      if(!["/users", "/analytics", "/app-version", "/user-info/:userId", "/instore-sku",
          "/create-user", "/create-branch", "/branch-info/:branchId", "/branches",
          "/widgets", "/widget-info/:widgetId", "/create-widget", "/giftwraps", "/giftwrap/:id", "/gras",
          ].includes(route.path)) {
      return route
    }
  })
} 

export default privateRoutes;
