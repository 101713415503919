import Barcode from "react-barcode";

export const militaryToStandardTime = (time) => {
  time = time.split(":"); // convert to array

  let hours = Number(time[0]);
  let minutes = Number(time[1]);

  // calculate
  let timeValue;
  if (hours > 0 && hours <= 12) {
    timeValue = "" + hours;
  } else if (hours > 12) {
    timeValue = "" + (hours - 12);
  } else if (hours === 0) {
    timeValue = "12";
  }

  timeValue += minutes < 10 ? ":0" + minutes : ":" + minutes; // get minutes
  timeValue += hours >= 12 ? " P.M." : " A.M."; // get AM/PM

  return timeValue;
};

export const camelToPhrase = (val) => {
  const result = val.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const getEventTypeObject = (eventType) => {
  console.log(eventType);
  const eventTypeObj = eventTypes.filter((el) => el.value === eventType);
  if (eventTypeObj && eventTypeObj.length > 0) {
    return eventTypeObj[0];
  }
  return "";
};

export const DisplaySkuBarcode = (props) => {
  const { value, height = 50, width = 0.8, fontSize = 10 } = props;
  if (value) {
    const barcode = getBarcode(value);
    return <Barcode value={barcode} height={height} margin={0} width={width} fontSize={fontSize} />;
  }
  return <Barcode value="" height={height} margin={0} width={width} fontSize={fontSize} />;
};

const getBarcode = (barcode) => {
  return barcode.length > 12 ? barcode : barcode + eanCheckDigit(barcode);
};

const eanCheckDigit = (upcValue) => {
  var result = 0;

  let splitext = upcValue.split("");
  let revertext = splitext.reverse();
  let reversed = revertext.join("");

  for (let counter = 0; counter < reversed.length; counter++) {
    result = result + parseInt(reversed.charAt(counter)) * Math.pow(3, (counter + 1) % 2);
  }

  return (10 - (result % 10)) % 10;
};

const eventTypes = [
  { id: "Wedding", value: "Wedding", code: "10" }
];
