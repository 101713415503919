import React, { useState, useContext } from "react";
import { makeStyles } from "@mui/styles";
import { 
    Button, 
    Dialog, 
    DialogContent, 
    DialogTitle, 
    FormControl,
    Grid,
    TextField,
    DialogActions
} from "@mui/material";
import { useMutation } from "@apollo/client";
import { AppContext } from "../../core/AppContext";

import { UPDATE_OFFLINE_ORDER } from '../../registry/api/mutations';

const useStyles = makeStyles({
  formControl: {
    width: "100%",
  },
});

const EditInstoreCustomerName = (props) => {
  const { updateDialogOpen, setUpdateDialogOpen, offlineOrder, resetRegData } = props;
  const [doUpdateOfflineOrder] = useMutation(UPDATE_OFFLINE_ORDER);
  const [offlineOrderInput, setOfflineOrderInput] = useState({
    _id: offlineOrder?._id,
    firstName: offlineOrder?.firstName,
    lastName: offlineOrder?.lastName,
    tid: offlineOrder?.tid,
    tr: offlineOrder?.tr,
    remarks: offlineOrder?.remarks
  });
  const { showSnackBar } = useContext(AppContext);
  const classes = useStyles();

  const handleInputChange = (event) => {
    console.log('offlineOrderInput', offlineOrderInput)
    const { name, value } = event.target
    setOfflineOrderInput({
        ...offlineOrderInput,
        [name]: value
    })
  }

  const updateOfflineOrder = async (offlineOrder, evt) => {
    const { loading } = await doUpdateOfflineOrder({
      variables: {
        params: {
          _id: offlineOrderInput?._id,
          firstName: offlineOrderInput?.firstName,
          lastName: offlineOrderInput?.lastName,
          tid: offlineOrderInput?.tid,
          tr: offlineOrderInput?.tr,
          remarks: offlineOrderInput?.remarks
        }
      },
    });
    if (!loading) {
        await showSnackBar({
            open: true,
            message: "Successfully updated!",
            severity: "info",
        });
        resetRegData();
        setUpdateDialogOpen(false)
    }
  };

  return (
    <div>
      <Dialog open={updateDialogOpen} onClose={() => setUpdateDialogOpen(false)} fullWidth maxWidth="sm">
        <DialogTitle>Edit Details </DialogTitle>
        <DialogContent dividers>
          <div className={classes.contentRoot}>
            <Grid container spacing={2}>
              <Grid item sm={6} spacing={2}>
                <TextField
                  variant="outlined"
                  label="Customer First Name"
                  name="firstName"
                  defaultValue={offlineOrder.firstName || ""}
                  onChange={(e) => handleInputChange(e)}
                  fullWidth
                />
              </Grid>
              <Grid item sm={6}>
                <TextField
                  variant="outlined"
                  label="Customer Last Name"
                  name="lastName"
                  defaultValue={offlineOrder.lastName || ""}
                  onChange={(e) => handleInputChange(e)}
                  fullWidth
                />
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={2}>
              <Grid item sm={6} spacing={2}>
                <TextField
                  variant="outlined"
                  label="TR"
                  name="tr"
                  defaultValue={offlineOrder.tr || ""}
                  onChange={(e) => handleInputChange(e)}
                  fullWidth
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                />
              </Grid>
              <Grid item sm={6}>
                <TextField
                  variant="outlined"
                  label="TID"
                  name="tid"
                  defaultValue={offlineOrder.tid || ""}
                  onChange={(e) => handleInputChange(e)}
                  fullWidth
                />
              </Grid>
            </Grid>
            <br />

            <Grid container spacing={2}>
              <Grid item sm={12} >
                <TextField
                  fullWidth
                  id="remarks"
                  label="Remarks"
                  name="remarks"
                  multiline
                  rows={4}
                  defaultValue={offlineOrder.remarks || ""}
                  onChange={(e) => handleInputChange(e)}
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button 
            size="small" 
            component="div" 
            variant="contained"
            onClick={() => {
              updateOfflineOrder()
            }}
          >
            Update
          </Button>
          <Button 
            size="small" 
            component="div" 
            variant="contained"
            color="error"
            onClick={() => {
              setUpdateDialogOpen(false)
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditInstoreCustomerName;
