import React, { useState, useContext } from "react";
import { makeStyles } from "@mui/styles";
import { 
    Button, 
    Dialog, 
    DialogContent, 
    DialogTitle, 
    FormControl,
    Grid,
    TextField,
    DialogActions
} from "@mui/material";
import { useMutation } from "@apollo/client";
import { AppContext } from "../../core/AppContext";

import { UPDATE_SHOPIFY_CHECKOUT } from '../../registry/api/mutations';

const useStyles = makeStyles({
  formControl: {
    width: "100%",
  },
});

const AddShopifyCheckoutRemarks = (props) => {
  const { updateDialogOpen, setUpdateDialogOpen, checkout, doFetchData, reload } = props;
  const [doUpdateShopifyCheckout] = useMutation(UPDATE_SHOPIFY_CHECKOUT);
  const [shopifyCheckoutInput, setShopifyCheckoutInput] = useState({
    _id: checkout?._id,
    remarks: checkout?.remarks
  });
  const { showSnackBar } = useContext(AppContext);
  const classes = useStyles();

  const handleInputChange = (event) => {
    const { name, value } = event.target
    setShopifyCheckoutInput({
        ...shopifyCheckoutInput,
        [name]: value
    })
  }

  const updateShopifyCheckout = async (checkout, evt) => {
    const { loading } = await doUpdateShopifyCheckout({
      variables: {
        params: {
          _id: shopifyCheckoutInput?._id,
          remarks: shopifyCheckoutInput?.remarks
        }
      },
    });
    if (!loading) {
      await showSnackBar({
        open: true,
        message: "Successfully updated!",
        severity: "info",
      });
      await doFetchData()
      setUpdateDialogOpen(false)
      
      reload()
    }
  };

  return (
    <div>
      <Dialog open={updateDialogOpen} onClose={() => setUpdateDialogOpen(false)} fullWidth maxWidth="sm">
        <DialogTitle>Add/Edit Remarks </DialogTitle>
        <DialogContent dividers>
          <div className={classes.contentRoot}>
            <Grid container spacing={2}>
              <Grid item sm={12} >
                <TextField
                  fullWidth
                  id="remarks"
                  label="Remarks"
                  name="remarks"
                  multiline
                  rows={4}
                  defaultValue={checkout.remarks || ""}
                  onChange={(e) => handleInputChange(e)}
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button 
            size="small" 
            component="div" 
            variant="contained"
            color="error"
            onClick={() => {
              setUpdateDialogOpen(false)
            }}
          >
            Cancel
          </Button>
          <Button 
            size="small" 
            component="div" 
            variant="contained"
            onClick={() => {
                updateShopifyCheckout()
            }}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddShopifyCheckoutRemarks;
