'use client';

import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  TextField,
} from "@mui/material";
import numeral from "numeral";
import { useApolloClient, useMutation } from "@apollo/client";

import { AppContext } from "../../core/AppContext";
import { GET_SKU_BY_BARCODE } from "../../sku/api/queries"
import { ADD_WISHLIST_ITEM } from "../../registry/api/mutations"

const AddItem = (props) => {
  const client = useApolloClient();
  const [isBundle, setIsBundle] = useState(false)
  const [quantity, setQuantity] = useState(1)
  const [bundleQty, setBundleQty] = useState(2)
  const [skuBarcode, setSkuBarcode] = useState("")
  const [productDetails, setProductDetails] = useState(null)
  const [isLoading, setIsLoading] = useState(false);
  const { showSnackBar, showProgressIndicator } = useContext(AppContext);
  const [doAddWishlistItem] = useMutation(ADD_WISHLIST_ITEM);

  let {
    wishlist,
    addItemDialog,
    setAddItemDialog,
    fetchAndResetRegData
  } = props;

  console.log('params', {
    quantity,
    bundleQty,
    wishlist
  })
  const [wishlistItemsClone, setWishlistItemsClone] = useState([]);

  const searchSku = async () => {
    showProgressIndicator(true);
    const { loading, data } = await client.query({
      query: GET_SKU_BY_BARCODE,
      variables: {
        skuBarcode
      },
      fetchPolicy: "network-only",
    });

    if (!loading) {
      const { getSkuByBarcode } = data;
      if (getSkuByBarcode) {
        setProductDetails(getSkuByBarcode)
        showProgressIndicator(false)
        showSnackBar({
          open: true,
          message: `Hoooray! We found a matching SKU in our database!`,
          severity: "info",
        });
      } else {
        setProductDetails(null)
        showProgressIndicator(false)
        showSnackBar({
          open: true,
          message: skuBarcode ? `Sorry we cannot find SKU with Barcode ${skuBarcode}` : `Sorry we cannot find SKU`,
          severity: "error",
        });
      }
    }
  };

  console.log('productDetails', productDetails)

  const checkBundle = (event) => {
    const { checked } = event.target;
    setIsBundle(checked)
  };

  const addWishlistItem = async () => {
    showProgressIndicator(true)
    const { loading, data } = await doAddWishlistItem({
      variables: {
        skuId: productDetails?._id,
        babySkuCode: productDetails?.babySkuCode,
        wishlistId: wishlist,
        quantity: Number(quantity),
        isBundle: isBundle,
        bundleQty: Number(bundleQty),
      },
    });

    console.log('data', data)
    const res = JSON.parse(data.addWishlistItem)
    if(res.success) {
      await showSnackBar({
        open: true,
        message: "Item successfully added.",
        severity: "success",
      });
      showProgressIndicator(false)
      setAddItemDialog(false)
      resetParams()
      fetchAndResetRegData()
    }
    else {
      await showSnackBar({
        open: true,
        message: res.message,
        severity: "error",
      });
      showProgressIndicator(false)
    }
  };

  const cancelBtn = async () => {
    setAddItemDialog(false)
    resetParams()
  }

  const resetParams = () => {
    setIsBundle(null)
    setSkuBarcode(null)
    setProductDetails(null)
  }

  return (
    <Dialog open={addItemDialog} onClose={() => setAddItemDialog(false)} fullWidth maxWidth="sm">
      <DialogTitle>Add Item</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={1}>
          <Grid item sm={10}>
            <TextField
              variant="outlined"
              label="Search Barcode"
              name="skubarcode"
              required
              fullWidth
              onChange={(e) => {
                setSkuBarcode(e.target.value)
              }}
            />
          </Grid>
          <Grid item sm={2}>
            <Button 
                size="lg"
                label="Search"
                variant="contained"
                color="secondary"
                onClick={() => {
                  searchSku()
                }}
              > Search
            </Button>
          </Grid>
          {
            productDetails && (
              <Grid  item sm={12} >
                <Grid item sm={12}>
                  {/* <Grid container style={{ border: "solid 1px #cccccc", padding: 5 }}> */}
                    <Grid item sm={12}>
                      <div><strong>Product Name:</strong> {productDetails.productName}</div>
                      <div><strong>SKU:</strong> {productDetails.skuCode}</div>
                      <div><strong>Price:</strong> {numeral(productDetails.currentPrice).format("0,000.00")}</div>
                    </Grid>
                  {/* </Grid> */}
                </Grid>
                <Grid container spacing={1} style={{ marginTop: '1rem'}}>
                  <Grid sm={12}>
                    <Checkbox 
                      onChange={checkBundle} checked={isBundle}
                    /> Is Bundle?
                  </Grid>
                  <Grid item sm={3}>
                    <TextField 
                      type="number"
                      label="Wants"
                      name="quantity"
                      value={quantity}
                      defaultValue={quantity}
                      onChange={(e) => {
                        setQuantity(e.target.value)
                      }}
                      InputProps={{
                        inputProps: { 
                          min: 1 
                        }
                      }}
                    />
                  </Grid>
                  {
                    isBundle && (
                      <Grid item sm={3}>
                        <TextField 
                          type="number"
                          label="Quantity"
                          name="bundleQty"
                          value={bundleQty}
                          defaultValue={quantity}
                          onChange={(e) => {
                            setBundleQty(e.target.value)
                          }}
                          InputProps={{
                            inputProps: { 
                               min: 2 
                            }
                          }}
                        />
                      </Grid>
                    )
                  }
                </Grid>
              </Grid>
            )
          }
        </Grid>
        <DialogActions>
          <Button 
            onClick={() => cancelBtn() } 
            variant="contained" 
            color="error"
          >
            CANCEL
          </Button>
          <Button
            onClick={addWishlistItem}
            variant="contained"
            color="primary"
            disabled={productDetails ? false : true }
          >
            SUBMIT
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default AddItem;
