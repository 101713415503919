import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { EVENT_SUB_TYPE } from "../../../constants";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  formControl: {
    width: "100%",
  },
  switchTyp: {
    margin: 12,
    padding: 5,
    fontSize: 14,
    color: "rgba(0, 0, 0, 0.54)",
  },
  switchTypActive: {
    margin: 12,
    padding: 5,
    fontSize: 14,
    color: "#000000",
  },
}));

const RegistryForm = (props) => {
  const { registry, updateRegistry, disabled, toggleDiscount, totalHave, totalReserved } = props;
  const classes = useStyles();
  const [confirmOpen, setConfirmOpen] = useState(false);

  const handleDateChange = (name, evt) => {
    const data = { target: { name, value: evt } };
    updateRegistry(data);
  };

  const handleSwitchChange = (event) => {
    const data = { target: { name: event.target.name, value: event.target.checked } };
    updateRegistry(data);
  };

  const handleDiscount = async (event) => {
    setConfirmOpen(true);
    // handleSwitchChange(event)
    // await toggleDiscount(event.target.checked)
  };

  const confirmClaim = async () => {
    const data = { target: { name: "discountClaimed", value: true } };
    updateRegistry(data);
    await toggleDiscount(true);
    setConfirmOpen(false);
  };

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12} sm={12}>
        <h2>Event Information</h2>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl className={classes.formControl}>
          <TextField
            disabled={disabled}
            variant="outlined"
            label="Event Name"
            name="name"
            defaultValue={registry.name || ""}
            onChange={updateRegistry}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl className={classes.formControl} variant="outlined">
          <InputLabel>Type</InputLabel>
          <Select
            disabled={true}
            labelId="subType-label"
            label="Type"
            name="subType"
            onChange={(evt) => updateRegistry(evt)}
            // value={"registry.subType"}
            defaultValue={'wedding'}
          >
            {EVENT_SUB_TYPE.map((subType) => (
              <MenuItem key={subType.type} value={subType.type}>
                {subType.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {/* <Box display="flex" flexGrow={1}>
          <Box display="flex">
            <Typography className={disabled ? classes.switchTyp : classes.switchTypActive}>
              Discount Claimed?
            </Typography>
          </Box>
          <Box display="flex" flexGrow={1}>
            <FormControlLabel
              control={
                <Switch
                  checked={registry.discountClaimed}
                  disabled={registry.discountClaimed || disabled}
                  onChange={(evt) => handleDiscount(evt)}
                  name="discountClaimed"
                  color="primary"
                />
              }
              label={registry.discountClaimed ? "Claimed" : "Not yet claimed"}
            />

            <Dialog open={confirmOpen} onClose={() => setConfirmOpen(false)}>
              <DialogTitle>Confirm in-store discount redemption</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  This action will permanently disable online discount for the registrant. Would you like to proceed?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setConfirmOpen(false)}>No</Button>
                <Button onClick={() => confirmClaim()} autoFocus>
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </Box> */}
      </Grid>
      {/* <Grid item xs={12} sm={12}>
        <FormControl className={classes.formControl}>
          <TextField
            disabled={disabled}
            variant="outlined"
            label="Event Summary"
            name="summary"
            defaultValue={registry.summary || ""}
            onChange={updateRegistry}
          />
        </FormControl>
      </Grid> */}
      <Grid item xs={12} sm={6}>
        <FormControl className={classes.formControl}>
          <TextField
            disabled={disabled}
            variant="outlined"
            label="Celebrant First Name"
            name="celebrantFirstName"
            defaultValue={registry.celebrantFirstName || ""}
            onChange={updateRegistry}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl className={classes.formControl}>
          <TextField
            disabled={disabled}
            variant="outlined"
            label="Celebrant Last Name"
            name="celebrantLastName"
            defaultValue={registry.celebrantLastName || ""}
            onChange={updateRegistry}
          />
        </FormControl>
      </Grid>
      {/* <Grid item xs={12} sm={6}></Grid>
      <Grid item xs={12} sm={6}></Grid> */}
      <Grid item xs={12} sm={6}>
        <FormControl className={classes.formControl}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Event Date"
              disabled={disabled}
              inputVariant="outlined"
              name="eventDate"
              format="MMM dd yyyy"
              fullWidth={true}
              value={registry.eventDate}
              onChange={(evt) => handleDateChange("eventDate", evt)}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl className={classes.formControl}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label={registry.isPickup ? "Pickup Date" : "Delivery Date"}
              disabled={disabled}
              inputVariant="outlined"
              name="deliveryDate"
              format="MMM dd yyyy"
              fullWidth={true}
              value={registry.deliveryDate}
              onChange={(evt) => handleDateChange("deliveryDate", evt)}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl className={classes.formControl}>
          <Box display="flex" flexGrow={1}>
            <Box display="flex">
              <Typography className={classes.switchTyp}>Delivery/Pickup</Typography>
            </Box>
            <Box display="flex" flexGrow={1}>
              <FormControlLabel
                control={
                  <Switch
                    checked={registry.isPickup}
                    disabled={disabled || totalHave || totalReserved}
                    onChange={(evt) => handleSwitchChange(evt)}
                    name="isPickup"
                  />
                }
                label={registry.isPickup ? "Pickup" : "Delivery"}
              />
            </Box>
          </Box>
        </FormControl>
      </Grid>
      {/* <Grid item xs={12} sm={6}>
        <FormControl className={classes.formControl}>
          <Box display="flex" flexGrow={1}>
            <Box display="flex">
              <Typography className={classes.switchTyp}>Visibility</Typography>
            </Box>
            <Box display="flex" flexGrow={1}>
              <FormControlLabel
                control={
                  <Switch
                    checked={registry.isPublic}
                    disabled={disabled}
                    onChange={(evt) => handleSwitchChange(evt)}
                    name="isPublic"
                  />
                }
                label={registry.isPublic ? "Public" : "Private"}
              />
            </Box>
          </Box>
        </FormControl>
      </Grid> */}
    </Grid>
  );
};

export default RegistryForm;
